function ArticlesCard({onComponentChange, data})
{

  const handleNavItemClick = (item) => {
    onComponentChange(item, data);
};
    return(
        <div className="col-sm-12 col-md-6 col-lg-4" >
        <div className="post-item" style={{minHeight: "500px"}} onClick={() => handleNavItemClick('blog', data)}>
          <div className="post__img">
            <a href="#">
              <img src={data.image} alt="post image" loading="lazy" />
            </a>
          </div>
          <div className="post__body">
            <div className="post__meta-cat">
              <a href="#">Health</a><a href="#">Tips</a>
            </div>
            <div className="post__meta d-flex">
              <span className="post__meta-date">{data.date}</span>
              <a className="post__meta-author" href="#">John Ezak</a>
            </div>
            <h4 className="post__title"><a href="#">{data.title}</a></h4>
            <p className="post__desc">
              {data.descriptionTitle}
            </p>
            <a className="btn btn__secondary btn__link btn__rounded">
              <span onClick={() => handleNavItemClick('blog', data)}>Read More</span>
              <i className="icon-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    )

}
export default ArticlesCard