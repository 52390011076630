import './NetworkGrowth.css';


function NetworkGrowth() {
  return (
    <div style={{textAlign: "center", fontFamily: "Lato, sans-serif"}}>
      <h1 style={{color: "#2685c5", fontWeight: "bolder", fontSize: "28px",textTransform: "uppercase"}}>Our Network Of Growth</h1>
      <ul className="timeline">
        {/* Item 1 */}
        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "20px"}}>TALAT Medical Center</span>
              <span className="time-wrapper"><span className="time">1994</span></span>
              <img src="assets/images/clients/Tmc.png" style={{width: "120px", marginTop: "-10px"}}/>
            </div>
          </div>
        </li>
        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "20px"}}>ScandCare Medical Center</span>
              <span className="time-wrapper"><span className="time">2001</span></span>
              <img src="assets/images/clients/Scand.png" style={{width: "110px", marginTop: "-30px"}}/>
            </div>
          </div>
        </li>

        {/* Item 2 */}
        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "20px"}}>Majestic Medical Center</span>
              <span className="time-wrapper"><span className="time">2005</span></span>
              <img src="assets/images/clients/Majestic.png" style={{width: "110px"}}/>
            </div>
          </div>
        </li>

        {/* Item 3 */}

        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "20px"}}>Ivory Medical Center</span>
              <span className="time-wrapper"><span className="time">2016</span></span>
              <img src="assets/images/clients/Ivory.png" style={{width: "130px", marginTop: "-30px"}}/>
            </div>
          </div>
        </li>

        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "20px"}}>Lauretta Medical Center</span>
              <span className="time-wrapper"><span className="time">2016</span></span>
              <img src="assets/images/clients/Lauretta.png" style={{width: "110px"}}/>
            </div>
          </div>
        </li>

        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "20px"}}>NewLook Khabisi Medical Center</span>
              <span className="time-wrapper"><span className="time">2017</span></span>
              <img src="assets/images/clients/NewLook.png" style={{width: "90px"}}/>
            </div>
          </div>
        </li>
        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "19px"}}>NewLook Extra Mall Medical Center</span>
              <span className="time-wrapper"><span className="time">2017</span></span>
              <img src="assets/images/clients/NewLook.png" style={{width: "90px"}}/>
            </div>
          </div>
        </li>
        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "20px"}}>NewLook Al Yahar Medical Center</span>
              <span className="time-wrapper"><span className="time">2018</span></span>
              <img src="assets/images/clients/NewLook.png" style={{width: "90px"}}/>
            </div>
          </div>
        </li>

        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "20px"}}>Wldy Medical Center</span>
              <span className="time-wrapper"><span className="time">2018</span></span>
              <img src="assets/images/clients/Wldy.png" style={{width: "130px", marginTop: "-10px"}}/>
            </div>
          </div>
        </li>

        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "20px"}}>Nazek Medical Center</span>
              <span className="time-wrapper"><span className="time">2020</span></span>
              <img src="assets/images/clients/Nazek.png" style={{width: "120px", marginTop: "-30px"}}/>
            </div>
          </div>
        </li>

        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "20px"}}>True Life Primary Care Center</span>
              <span className="time-wrapper"><span className="time">2022</span></span>
              <img src="assets/images/clients/TrueLife.png" style={{width: "120px"}}/>

            </div>
          </div>
        </li>

        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "14px"}}>American Academy of Cosmetic Surgery Hospital</span>
              <span className="time-wrapper"><span className="time">2023</span></span>
              <img src="assets/images/clients/aacsh.png" style={{width: "80px"}}/>

            </div>
          </div>
        </li>

        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag" style={{fontWeight: "bolder", fontSize: "14px"}}>Emirates Medical Center L.L.C</span>
              <span className="time-wrapper"><span className="time">2024</span></span>
              <img src="assets/images/clients/EMC.png" style={{width: "80px"}}/>

            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}
export default NetworkGrowth