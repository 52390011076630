// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fadein img {
    
    -webkit-animation-name: fade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 6s;
    animation-name: fade;
    animation-iteration-count: infinite;
    animation-duration: 6s;
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    33% {
        opacity: 1;
    }

    53% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

#f1 {
    background-color: lightblue;
}

#f2 {
    -webkit-animation-delay: -4s;
    background-color: yellow;
}

#f3 {
    -webkit-animation-delay: -2s;
    background-color: lightgreen;
}`, "",{"version":3,"sources":["webpack://./src/Components/Slider/Slider.css"],"names":[],"mappings":"AAAA;;IAEI,4BAA4B;IAC5B,2CAA2C;IAC3C,8BAA8B;IAC9B,oBAAoB;IACpB,mCAAmC;IACnC,sBAAsB;AAC1B;;AAwBA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,4BAA4B;IAC5B,wBAAwB;AAC5B;;AAEA;IACI,4BAA4B;IAC5B,4BAA4B;AAChC","sourcesContent":[".fadein img {\r\n    \r\n    -webkit-animation-name: fade;\r\n    -webkit-animation-iteration-count: infinite;\r\n    -webkit-animation-duration: 6s;\r\n    animation-name: fade;\r\n    animation-iteration-count: infinite;\r\n    animation-duration: 6s;\r\n}\r\n\r\n@-webkit-keyframes fade {\r\n    0% {\r\n        opacity: 0;\r\n    }\r\n\r\n    20% {\r\n        opacity: 1;\r\n    }\r\n\r\n    33% {\r\n        opacity: 1;\r\n    }\r\n\r\n    53% {\r\n        opacity: 0;\r\n    }\r\n\r\n    100% {\r\n        opacity: 0;\r\n    }\r\n}\r\n\r\n@keyframes fade {\r\n    0% {\r\n        opacity: 0;\r\n    }\r\n\r\n    20% {\r\n        opacity: 1;\r\n    }\r\n\r\n    33% {\r\n        opacity: 1;\r\n    }\r\n\r\n    53% {\r\n        opacity: 0;\r\n    }\r\n\r\n    100% {\r\n        opacity: 0;\r\n    }\r\n}\r\n\r\n#f1 {\r\n    background-color: lightblue;\r\n}\r\n\r\n#f2 {\r\n    -webkit-animation-delay: -4s;\r\n    background-color: yellow;\r\n}\r\n\r\n#f3 {\r\n    -webkit-animation-delay: -2s;\r\n    background-color: lightgreen;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
