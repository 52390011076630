import logo from './logo.svg';
import './App.css';
import Slider from './Components/Slider/Slider';
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import AboutUs from './Components/AboutUs/AboutUs';
import React, { useState } from 'react';
import News from './Components/News/News';
import ContactUs from './Components/ContactUs/ContactUs';
import Blog from './Components/Blog/Blog';
import FloatingMenu from './Components/FloatingMenu/FloatingMenu';
import Cities from './Components/Cities/Cities';
import Careers from './Components/Careers/Careers';
import underConstruction from './Components/underConstruction/underConstruction';
function App() {

  const [activeComponent, setActiveComponent] = useState('home');
  const [activeArticle, setActiveArticle] = useState(null);
  const [activeCity, setActiveCity] = useState(null);

  const handleComponentChange = (component, data, city) => {
    console.log(city)
    console.log(data)
    setActiveComponent(component);
    setActiveArticle(data)
    setActiveCity(city)

  };

  return (
    <div>
      <Navbar onComponentChange={handleComponentChange} />
      {activeComponent === 'home' && <Home onComponentChange={handleComponentChange} />}
      {activeComponent === 'about' && <AboutUs onComponentChange={handleComponentChange} />}
      {activeComponent === 'news' && <News onComponentChange={handleComponentChange} />}
      {activeComponent === 'contact' && <ContactUs onComponentChange={handleComponentChange} />}
      {activeComponent === 'blog' && <Blog onComponentChange={handleComponentChange} article={activeArticle}/>}
      {activeComponent === 'cities' && <Cities onComponentChange={handleComponentChange} city={activeCity}/>}
      {activeComponent === 'careers' && <Careers onComponentChange={handleComponentChange}/>}
      {activeComponent === 'emc' && <underConstruction onComponentChange={handleComponentChange}/>}



      


      <FloatingMenu />
      <Footer onComponentChange={handleComponentChange}/>
    </div>


  );
}

export default App;
