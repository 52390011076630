import { click } from "@testing-library/user-event/dist/click";
import BranchesCard from "../BranchesCard/BranchesCard";
import React, { useState } from 'react';

function Cities({ onComponentChange, city }) {
    console.log(city)
    const [activeCity, setActiveCity] = useState(city)
    const brandsData = [
        { id: 1, imagePath: 'assets/images/clients/TrueLife.png', alt: 'Brand 1', city: "Al Ain", building: 'assets/Branches/TrueLife.jpg', click: 'https://truelifehospital.ae/' },
        { id: 2, imagePath: 'assets/images/clients/Nazek.png', alt: 'Brand 2', city: "Al Ain", building: 'assets/Branches/Nazek.jpg' },
        { id: 4, imagePath: 'assets/images/clients/Cosmo.png', alt: 'Brand 4', city: "Dubai", building: 'assets/Branches/Jumeirah.jpg', click: 'https://www.cosmohealthmc.ae/' },
        { id: 5, imagePath: 'assets/images/clients/Ivory.png', alt: 'Brand 5', city: "Al Ain", building: 'assets/Branches/Ivory.jpg' , click: 'https://www.ivorymc.ae/'},
        { id: 7, imagePath: 'assets/images/clients/NewLook.png', alt: 'Brand 7', city: "Al Ain", building: 'assets/Branches/NewLook.jpg', click: 'https://www.newlookmc.ae/' },
        { id: 8, imagePath: 'assets/images/clients/Lauretta.png', alt: 'Brand 8', city: "Al Ain", building: 'assets/Branches/Lauretta1.jpg', click: "https://www.laurettamc.ae/" },
        { id: 9, imagePath: 'assets/images/clients/Wldy.png', alt: 'Brand 9', city: "Al Ain", building: 'assets/Branches/Wldy.jpg',click: "https://wldy.ae/" },
        { id: 10, imagePath: 'assets/images/clients/Tmc.png', alt: 'Brand 10', city: "Abu Dhabi", building: 'assets/Branches/TALA.jpg' },
        { id: 11, imagePath: 'assets/images/clients/Scand.png', alt: 'Brand 11', city: "Al Ain", building: 'assets/Branches/ScandCare.jpg' },
        { id: 12, imagePath: 'assets/images/clients/Majestic.png', alt: 'Brand 12', city: "Abu Dhabi", building: 'assets/Branches/Majestic.jpg', click: "https://majesticdc.ae/" },
        { id: 13, imagePath: 'assets/images/clients/aacsh.png', alt: 'Brand 14', city: "Dubai", building: 'assets/Branches/AACSH.jpg', click: "https://www.aacsh.com/" },
        { id: 14, imagePath: 'assets/images/clients/Cosmo.png', alt: 'Brand 14', city: "Ajman", building: 'assets/Branches/Ajman.jpg', click: 'https://www.cosmohealthmc.ae/' },
        { id: 15, imagePath: 'assets/images/clients/Cosmo.png', alt: 'Brand 14', city: "RAK", building: 'assets/Branches/CosmoRAK.jpg', click: 'https://www.cosmohealthmc.ae/' },
        { id: 15, imagePath: 'assets/images/clients/Cosmo.png', alt: 'Brand 14', city: "Sharjah", building: 'assets/Branches/Sharjah.jpg', click: 'https://www.cosmohealthmc.ae/' },
        { id: 15, imagePath: 'assets/images/clients/EMC.jpg', alt: 'Brand 69', city: "Al Ain", building: '', click: '' },


    ];

    const filteredBrandsData = brandsData.filter(brand => brand.city === city);

    return (
        <section className="features-layout2 pt-130 bg-overlay bg-overlay-primary">
            {/* <div className="bg-img"><img src="assets/images/backgrounds/2.jpg" alt="background"></div> */}
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="heading__layout2 mb-50">
                            <h3 className="heading__title" style={{color: "#2685c5", fontWeight: "bolder", fontSize: "28px"}}>
                                Discover Our Branches In {city}
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="row">
                   
                    {filteredBrandsData.map((brand, index) => (
                        <BranchesCard key={index} brandsData={brand} onComponentChange={onComponentChange}/>
                    ))}
                   
                </div>

            </div>
        </section>
    )
}
export default Cities