import emailjs from 'emailjs-com';


function ContactUs() {

    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_wf3ixho', 'template_jpigck6', e.target, 'CpPK7kPmoJtCc4w17')
            .then((result) => {
                window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });

    }

    return (
        <div>
            {/* Google Map Section */}


            {/* Contact Layout 1 Section */}
            <section className="contact-layout1 pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className=" d-flex flex-wrap">
                                {/* Contact Form */}
                                <form className="" onSubmit={sendEmail} id="contactForm">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h4 className="contact-panel__title" style={{fontWeight: "bolder", color: "#2685c5"}}>How Can We Help? </h4>
                                            <p className="contact-panel__desc mb-30" style={{  fontFamily: "Lato, sans-serif"}}>
                                                Please feel welcome to contact our friendly reception staff with any general or medical enquiry. Our
                                                doctors will receive or return any urgent calls.
                                            </p>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <i className="icon-user form-group-icon"></i>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    id="contact-name"
                                                    name="name"
                                                    required
                                                    style={{borderRadius: "1px"}}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <i className="icon-email form-group-icon"></i>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    id="contact-email"
                                                    name="email"
                                                    required
                                                    style={{borderRadius: "1px"}}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <i className="icon-phone form-group-icon"></i>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Phone"
                                                    id="contact-Phone"
                                                    name="phone"
                                                    required
                                                    style={{borderRadius: "1px"}}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <i className="icon-news form-group-icon"></i>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Subject"
                                                    id="contact-name"
                                                    name="subject"
                                                    required
                                                    style={{borderRadius: "1px"}}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <i className="icon-alert form-group-icon"></i>
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Message"
                                                    id="contact-message"
                                                    name="message"
                                                    style={{borderRadius: "1px"}}
                                                ></textarea>
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn__secondary btn__block btn__xhight mt-10"
                                                style={{width: "10%", backgroundColor: "#2685C5"}}
                                            >
                                                <span>Submit Request</span> <i className="icon-arrow-right"></i>
                                            </button>
                                            <div className="contact-result"></div>
                                        </div>
                                    </div>
                                </form>
                                {/* Info Panel */}
                                {/* <div>
                                    
                                    
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="google-map py-0">
            <iframe
                                        frameborder="0"
                                        height="500"
                                        width="100%"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3637.964476750261!2d55.718214075875494!3d24.243021178337578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8ab6caeb83a1eb%3A0xc448f109067c4146!2sTrust%20Vision%20Investment%20L.L.C!5e0!3m2!1sen!2sae!4v1692622309777!5m2!1sen!2sae"
                                        loading="lazy"
                                    ></iframe>
            </section>
        </div>
    )

}
export default ContactUs