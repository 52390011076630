function underConstruction () {
    return(
        <>
            <div style={{ position: 'absolute', top: 0, left: '16px' }}>
                <p>Logo</p>
            </div>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                <h1>COMING SOON</h1>
                <hr style={{ margin: 'auto', width: '40%' }} />
                <p>35 days left</p>
            </div>
            <div style={{ position: 'absolute', bottom: 0, left: '16px' }}>
                <p>Some text</p>
            </div>

        </>
    )

}
export default underConstruction