import { FaLinkedin } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";

import './Footer.css';

function Footer({ onComponentChange }) {

    const goToGoogleMaps = () => {
        // Replace the latitude and longitude values with the desired location
        const latitude = 25.2326705;
        const longitude = 55.3195453;

        // Open Google Maps in a new tab with the specified location
        window.open(`https://maps.app.goo.gl/rJkc6zdfKDURa2xWA`);
    };

    const handleNavItemClick = (item) => {
        onComponentChange(item, '');
    };
    return (
        <footer class="footer" style={{marginBottom: "-55px"}}>
            {/* <div class="waves">
                <div class="wave" id="wave1"></div>
                <div class="wave" id="wave2"></div>
                <div class="wave" id="wave3"></div>
                <div class="wave" id="wave4"></div>
            </div> */}
            <ul class="social-icon">
                <li class="social-icon__item"><a class="social-icon__link" href="https://ae.linkedin.com/company/trust-vision-investment">
                    <FaLinkedin />
                </a></li>
                <li class="social-icon__item"><a class="social-icon__link" href="https://wa.me/800888000888">
                    <FaWhatsapp />
                </a></li>
                <li class="social-icon__item"><a class="social-icon__link" href="tel:800888000888">
                    <FaPhoneAlt size={25}/>

                </a></li>
                
            </ul>
            {/* <ul class="menu">
                <li class="menu__item"><a onClick={() => handleNavItemClick('home')} class="menu__link" href="#">Home</a></li>
                <li class="menu__item"><a onClick={() => handleNavItemClick('about')} class="menu__link" href="#">About Us</a></li>
                <li class="menu__item"><a onClick={() => handleNavItemClick('news')} class="menu__link" href="#">News & Media</a></li>
                <li class="menu__item"><a onClick={() => handleNavItemClick('contact')} class="menu__link" href="#">Contact Us</a></li>
              

            </ul> */}
            <p>&copy;2023 Trust Vision Investment | All Rights Reserved</p>
        </footer>
    )

}
export default Footer