import Container from 'react-bootstrap/Container';


function Info() {
  return (

    <div className="row" style={{ backgroundColor: "#e6f1f8" }}>


      <div className="col-sm-6">

        <div className="row ml-30 mr-30 mt-20 py-3">
          <div className="heading-layout2" style={{ textAlign: 'start', color: "#FFFFFF" }}>
            <h3 className="heading__title mb-60" style={{ color: "#2685c5", fontWeight: "bolder", fontSize: "28px" }}>United in Pursuit of Better Healthcare Across Disciplines</h3>
          </div>
          {/* /heading */}

          <div className="text__content" style={{ textAlign: 'justify', color: "#FFFFFF" }}>
            <p style={{ color: "#0e204d", fontFamily: "Lato, sans-serif", marginTop: "-40px",fontSize: "16px" }}>At Trust Vision Investment, we stand as a
              prominent healthcare group, dedicating our efforts to enhancing the lives of
              individuals through a range of specialized services. With a strong presence across the United Arab
              Emirates, our clinics
              cater to a diverse array of healthcare needs. Our commitment to excellence shines through our
              comprehensive offerings in
              Cosmetic, Dermatology, Dental, Rehabilitation, and Multi-Specialty services.
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <p style={{ color: "#0e204d", fontFamily: "Lato, sans-serif", marginTop: "-40px" ,fontSize: "16px"}}>Our team of highly skilled and compassionate healthcare professionals work tirelessly, united in our pursuit of delivering exceptional care and improving healthcare outcomes across disciplines. At Trust Vision Investment, we understand the unique and evolving needs of our patients, and that is why we prioritize staying at the forefront of medical advancements and technology. By fostering a collaborative environment and promoting continuous learning, we ensure that our clinics remain centers of excellence, where patients can access cutting-edge treatments and personalized care. Whether it's a cosmetic procedure, dermatological treatment, dental care, rehabilitation, or specialized multi-disciplinary services, Trust Vision Investment is dedicated to providing the highest quality healthcare, empowering individuals to lead healthier and more fulfilling lives.
            </p>

          </div>
        </div>
      </div>

      <div className="col-sm-6">

        <div >
          <img src="assets/images/about/ab1.jpg" alt="about" style={{
            objectFit: 'fill',
            height: '100%',
            width: '100%',
            float: "right"
          }} />
        </div>

      </div>
    </div>

  )
}
export default Info