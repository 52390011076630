function About() {
  return (
    <div style={{ marginTop: "-150px" }}>
      <section className="about-layout1 pb-0">
        <div className="container">
          <div className="row" >
            <div className="col-sm-12 col-md-12">
              <div className="heading-layout2" >
                <h3 className="heading__title mb-40" style={{ fontWeight: "bolder", color: "#2685c5", fontSize: "28px" }}>United in Pursuit of Better Healthcare Across Disciplines.</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="video-banner">
                <img src="assets/images/sliders/1.jpg" alt="about" />
           
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="about__Text">
                <p className="mb-30" style={{ fontFamily: "Lato, sans-serif", fontWeight: "lighter", textAlign: "left" }}>TrustVision Group operates with a vision - to revolutionize healthcare access and
                  experiences across various medical disciplines. We are a unified group managing and operating an
                  expansive chain of medical facilities, including hospitals, dental and cosmetic centers, poly clinics,
                  and rehabilitation centers.
                </p>
                <p className="mb-30" style={{ fontFamily: "Lato, sans-serif", fontWeight: "lighter", textAlign: "left" }}>
                  Born out of a commitment to improving lives, TrustVision Group is the embodiment of progressive
                  healthcare. We operate across various specialty areas, providing end-to-end healthcare services to
                  communities. Our chain of healthcare facilities provides a wide spectrum of medical services, from
                  primary care to specialty treatments, and from preventive health measures to rehabilitation services.
                </p>
              </div>
            </div>

          </div>
        </div>
      </section>

      <div className="container">
        <div className="row mb-40">
          <div className="col-sm-12 col-md-12 col-lg-5">
            <h5 style={{ fontWeight: "bolder", color: "#2685c5", marginTop: "20px", textTransform: "uppercase", fontSize: "28px" }}>Our mission</h5>
            <div className="heading__layout2">
              <h3 className="heading__title" style={{ color: "#2685c5", fontSize: "26px", textTransform: "none" }}>Your health is our priority</h3>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-5 offset-lg-1">
            <p className="mission heading__desc mb-30" style={{ fontFamily: "Lato, sans-serif", fontWeight: "lighter", textAlign: "left" }}>
              Provide broad-spectrum, top-quality healthcare services by
              harnessing the collective strength of our diverse network of medical centers and hospitals.
              Deliver high-quality, accessible healthcare through innovation, a patient-centric culture, community
              commitment, and groundbreaking research.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mb-40">
          <div className="col-sm-12 col-md-12 col-lg-5">
            <h5 style={{ fontWeight: "bolder", color: "#2685c5", marginTop: "20px", textTransform: "uppercase", fontSize: "28px" }}>Our vision</h5>
            <div className="heading__layout2">
              <h3 className="heading__title" style={{ color: "#2685c5", fontSize: "26px", textTransform: "none"  }}>World-class healthcare accessible to all</h3>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-5 offset-lg-1">
            <p className="mission heading__desc mb-30" id="values" style={{ fontFamily: "Lato, sans-serif", fontWeight: "lighter", textAlign: "left" }}>
              We aspire to be an eminent force in the health sector in UAE and all GCC, transforming lives through
              unparalleled care and advanced medical solutions. Our dream is to make world-class healthcare accessible
              to all, with our chain of medical centers and hospitals as beacons of medical excellence, compassion, and
              innovation
            </p>
          </div>
        </div>
      </div>
    </div>
  )

}
export default About