import * as icons from "react-icons/fa";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function BranchesCard({ brandsData, onComponentChange }) {
    console.log(brandsData);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Check the condition and render content based on the value of brandsData.alt
    if (brandsData.alt === 'Brand 69') {
        return (
            <>
                <div className="col-sm-6 col-md-6 col-lg-4">
                    <a onClick={handleShow} target="_blank" rel="noopener noreferrer">
                        <div className="feature-item" style={{ height: "200px" }}>
                            <div className="feature__img">
                                <img src={brandsData.building} alt="service" loading="lazy" />
                            </div>
                            <div className="feature__content">
                                <div className="feature__icon">
                                    {/* <i className={speciality.icon}></i> */}
                                    <img
                                        src={brandsData.imagePath}
                                        alt="service"
                                        loading="lazy"
                                        style={{ objectFit: "cover", width: "250px", height: "150px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </a>
                </div>

                <Modal show={show} onHide={handleClose} style={{width: "100%"}}>
                    <Modal.Body>
                        <div className="d-flex flex-column justify-content-between" style={{ height: '100%', width: "100%" }}>
                            <img src="assets/images/header.png" />

                            <div className="text-center">
                                <h1>Website Under Construction</h1>
                                <hr style={{ margin: 'auto', width: '40%' }} />
                                <p>
                                    <a href="tel:+971509302160">Contact Number: +971509302160</a>
                                </p><p>Location: Al Khabisi, Al Ain </p>

                            </div>
                            {/* <div className="text-start">
                                <p>Some text</p>
                            </div> */}
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    } else {
        return (
            <div className="col-sm-6 col-md-6 col-lg-4">
                <a href={brandsData.click} target="_blank" rel="noopener noreferrer">
                    <div className="feature-item" style={{ height: "200px" }}>
                        <div className="feature__img">
                            <img src={brandsData.building} alt="service" loading="lazy" />
                        </div>
                        <div className="feature__content">
                            <div className="feature__icon">
                                {/* <i className={speciality.icon}></i> */}
                                <img
                                    src={brandsData.imagePath}
                                    alt="service"
                                    loading="lazy"
                                    style={{ objectFit: "cover", width: "250px", height: "150px" }}
                                />
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

export default BranchesCard;
