function Blog({article}) {

    return (
        <div>
      {/* Page Title Section */}
      <section className="page-title pt-30 pb-30 text-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item"><a href="#">Blog</a></li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {article.title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      {/* Blog Single Section */}
      <section className="blog blog-single pt-0 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="post-item mb-0">
                <div className="post__img">
                  <a href="#">
                    <img src={article.image} alt="post image" loading="lazy" style={{width: "500px"}}/>
                  </a>
                </div>
                <div className="post__body pb-0">
                  <div className="post__meta-cat">
                    <a href="#">Health</a><a href="#">tips</a>
                  </div>
                  <div className="post__meta d-flex align-items-center mb-20">
                    <span className="post__meta-date">Aug 30, 2023</span>
                   
                  </div>
                  <h1 className="post__title mb-30">
                    {article.title}
                  </h1>
                  <div className="post__desc">
                    <p>
                      {article.description}
                    </p>
                    
                  </div>
                </div>
              </div>
            
             
            </div>
            
          </div>
        </div>
      </section>
    </div>
    )

}

export default Blog
