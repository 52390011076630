
import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import './Slider.css';


function Slider() {
    const images = [
        { id: 1, imagePath: 'assets/Branches/AACSH.jpg', alt: 'Brand 2' },
        { id: 2, imagePath: 'assets/Branches/Jumeirah.jpg', alt: 'Brand 4' },
        { id: 3, imagePath: 'assets/Branches/Ivory.jpg', alt: 'Brand 5' },
        { id: 4, imagePath: 'assets/Branches/Lauretta.jpg', alt: 'Brand 7' },
        { id: 5, imagePath: 'assets/Branches/Lauretta1.jpg', alt: 'Brand 8' },
        { id: 6, imagePath: 'assets/Branches/Majestic.jpg', alt: 'Brand 9' },
        { id: 7, imagePath: 'assets/Branches/Marina.jpg', alt: 'Brand 10' },
        { id: 8, imagePath: 'assets/Branches/Nazek.jpg', alt: 'Brand 11' },
        { id: 9, imagePath: 'assets/Branches/NewLook.jpg', alt: 'Brand 12' },
        { id: 10, imagePath: 'assets/Branches/Sharjah.jpg', alt: 'Brand 14' },
        { id: 10, imagePath: 'assets/Branches/CosmoRAK.jpg', alt: 'Brand 14' },
        { id: 12, imagePath: 'assets/Branches/TALA.jpg', alt: 'Brand 1' },
        { id: 13, imagePath: 'assets/Branches/Wldy.jpg', alt: 'Brand 1' },


    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        // Change the image every 3 seconds (adjust the interval as needed)
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5800);

        // Clear the interval when the component is unmounted
        return () => clearInterval(interval);
    }, [images.length]);


    return (
        <div className="row slider-bg text-center" style={{ backgroundColor: "#d0e7f9" }}>

            <div className="col-sm-6 align-self-center mb-30 mt-30" >
                <div className="ml-30 mr-30 py-30">
                    <h2 className="title" style={{ color: "#2685c5", fontSize: "28px", fontWeight: "bolder", textAlign: "left"}}>DIVERSE AND FAST GROWING</h2>
                    <p className="title" style={{ color: "#2685c5", fontSize: "26px", textAlign: "left"}}> Pioneering Investments in the Thriving Healthcare Industry</p>
                    <p className="heading__desc mb-30" style={{ textAlign: 'justify',color: "#0e204d", fontFamily: "Lato, sans-serif", textAlign: "left" }}>At Trust Vision Investment, we are dedicated to capitalizing on the exponential growth and diversity within the healthcare sector. With a keen focus on the United Arab Emirates (UAE), our investments are strategically designed to leverage the region's robust healthcare landscape, bolstered by progressive regulations and policies.</p>
                    <br />
                    <br />
                    <p className="heading__desc " style={{ color: "#0e204d",  fontFamily: "Lato, sans-serif", textAlign: "left",textAlign: 'justify'}}>Our visionary approach empowers us to identify and seize opportunities in the most promising segments of the industry, paving the way for groundbreaking advancements and transformative solutions. By combining our expertise with the dynamic nature of the healthcare sector, Trust Vision Investment is at the forefront of driving innovation, improving patient outcomes, and shaping the future of healthcare in the UAE.</p>

                </div>
            </div>
            <div className="col-sm-6">

                <div className='fadein'>
                    <img

                        src={images[currentImageIndex].imagePath}
                        alt={`Image ${currentImageIndex + 1}`}
                        style={{
                            objectFit: 'fill',
                            height: '100%',
                            width: '100%',

                        }}


                    />
                </div>
            </div>

        </div>
    )

}
export default Slider;