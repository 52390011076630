// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
/*   background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
  height: 150px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 960px;
}
.slider::before, .slider::after {
/*   background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); */
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide {
  height: 100px;
  width: 250px;
  padding-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Brands/Brands.css"],"names":[],"mappings":"AAAA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,uCAAuC;EACzC;AACF;AACA;AACA;qDACqD;EACnD,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;AACA;AACA,qGAAqG;EACnG,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,UAAU;AACZ;AACA;EACE,QAAQ;EACR,MAAM;EACN,0BAA0B;AAC5B;AACA;EACE,OAAO;EACP,MAAM;AACR;AACA;EACE,qCAAqC;EACrC,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":["@keyframes scroll {\r\n  0% {\r\n    transform: translateX(0);\r\n  }\r\n  100% {\r\n    transform: translateX(calc(-250px * 7));\r\n  }\r\n}\r\n.slider {\r\n/*   background: white;\r\n  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */\r\n  height: 150px;\r\n  margin: auto;\r\n  overflow: hidden;\r\n  position: relative;\r\n  width: 960px;\r\n}\r\n.slider::before, .slider::after {\r\n/*   background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); */\r\n  content: \"\";\r\n  height: 100px;\r\n  position: absolute;\r\n  width: 200px;\r\n  z-index: 2;\r\n}\r\n.slider::after {\r\n  right: 0;\r\n  top: 0;\r\n  transform: rotateZ(180deg);\r\n}\r\n.slider::before {\r\n  left: 0;\r\n  top: 0;\r\n}\r\n.slider .slide-track {\r\n  animation: scroll 40s linear infinite;\r\n  display: flex;\r\n  width: calc(250px * 14);\r\n}\r\n.slider .slide {\r\n  height: 100px;\r\n  width: 250px;\r\n  padding-left: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
