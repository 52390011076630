import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import React, { useEffect } from 'react';
import './Stats.css';


function Stats() {

    useEffect(() => {
        const runCounterUp = () => {
            // Counter-Up JS codes
            let x = document.querySelectorAll('.count');
            let arr = Array.from(x);

            arr.forEach((item) => {
                let count = parseInt(item.innerHTML, 10);
                item.innerHTML = '0';
                let countNumber = 0;
                const interval = setInterval(() => {
                    if (countNumber <= count) {
                        item.innerHTML = countNumber;
                        countNumber++;
                    } else {
                        clearInterval(interval);
                    }
                }, item.dataset.speed / count);
            });
        };

        // Run Counter-Up when the component mounts
        runCounterUp();

        // Clean up on component unmount
        return () => {
            // You can perform cleanup here if needed
        };
    }, []); // Empty dependency array to run the effect only once

    return (
        <div style={{ marginTop: "40px", backgroundColor: "#e6f1f8", textAlign: "center" }}>
            <div className="heading-layout2" >
                <h3 className=" mb-30" style={{ color: "#2685c5", fontWeight: "bolder", fontSize: "28px", textTransform: "uppercase" }}>touching lives across the world<br /></h3>

                <h3 className=" mb-30" style={{ color: "#2685c5", fontSize: "26px", textTransform: "none" }}>Our healthcare presence in region</h3>
            </div>

            <p className="heading__desc mb-60" style={{ color: "#0e204d", fontFamily: "Lato, sans-serif", textTransform: "None" }}>
                Discover our statistics footprint across the world, bringing accessible and premium healthcare services closer
                to you.
            </p>

            <Row >
                <Col lg={3}><Row style={{ minHeight: "100%" }}>
                    <div className="card shadow-sm animate animate-card border-box" style={{ textAlign: "center", float: "none", margin: "0 auto", marginBottom: "15px", backgroundColor: "#2685c5" }}>


                        <span style={{ fontSize: "50px", color: " white" }} className="count" data-speed="100">
                            5
                        </span>
                        <h4>
                            <small style={{ color: "white" }}>Countries</small>
                        </h4>

                        <span style={{ fontSize: "50px", color: " white" }} className="count" data-speed="100">
                            5
                        </span>
                        <h4>
                            <small style={{ color: "white" }}>Emirates</small>
                        </h4>
                    </div>
                </Row></Col>
                <Col lg={3}>
                    <Row >
                        <div className="card shadow-sm animate animate-card border-box" style={{ textAlign: "center", float: "none", margin: "0 auto", marginBottom: "15px", backgroundColor: "#2685c5" }}>


                            <span style={{ fontSize: "50px", color: " white" }} className="count" data-speed="100">
                                18
                            </span>
                            <h4>
                                <small style={{ color: "white" }}>Clinics</small>
                            </h4>
                        </div>
                    </Row>

                    <Row >
                        <div className="card shadow-sm animate animate-card border-box" style={{ textAlign: "center", float: "none", margin: "0 auto", marginBottom: "15px", backgroundColor: "#2685c5" }}>


                            <span style={{ fontSize: "50px", color: " white" }} className="count" data-speed="100">
                                225
                            </span>
                            <h4>
                                <small style={{ color: "white" }}>Doctors</small>
                            </h4>
                        </div>
                    </Row>


                </Col>
                <Col lg={3}>
                    <Row >
                        <div className="card shadow-sm animate animate-card border-box" style={{ textAlign: "center", float: "none", margin: "0 auto", marginBottom: "15px", backgroundColor: "#2685c5" }}>


                            <span style={{ fontSize: "50px", color: " white" }} className="count" data-speed="100">
                                2
                            </span>
                            <h4>
                                <small style={{ color: "white" }}>Hospitals</small>
                            </h4>
                        </div>
                    </Row>

                    <Row >
                        <div className="card shadow-sm animate animate-card border-box" style={{ textAlign: "center", float: "none", margin: "0 auto", marginBottom: "15px", backgroundColor: "#2685c5" }}>


                            <span style={{ fontSize: "50px", color: " white" }} >
                                704,804
                            </span>
                            <h4>
                                <small style={{ color: "white" }}>Patients</small>
                            </h4>
                        </div>
                    </Row>


                </Col>
                <Col lg={3}>
                    <Row >
                        <div className="card shadow-sm animate animate-card border-box" style={{ textAlign: "center", float: "none", margin: "0 auto", marginBottom: "15px", backgroundColor: "#2685c5" }}>


                            <span style={{ fontSize: "50px", color: " white" }} className="count" data-speed="100">
                                5
                            </span>
                            <h4>
                                <small style={{ color: "white" }}>Pharmacies</small>
                            </h4>
                        </div>
                    </Row>

                    <Row >
                        <div className="card shadow-sm animate animate-card border-box" style={{ textAlign: "center", float: "none", margin: "0 auto", marginBottom: "15px", backgroundColor: "#2685c5" }}>


                            <span style={{ fontSize: "50px", color: "white" }} className="count" data-speed="100">
                                4
                            </span>
                            <h4>
                                <small style={{ color: "white" }}>Labs</small>
                            </h4>
                        </div>
                    </Row>


                </Col>
            </Row>

        </div>
    )

}
export default Stats;