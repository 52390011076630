import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import emailjs from 'emailjs-com';
import { Container, Row } from 'react-bootstrap';

function Careers({ show, handleClose }) {

    function sendEmail(e) {
        console.log(e)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_wf3ixho', 'template_2rkryag', e.target, 'CpPK7kPmoJtCc4w17')
            .then((result) => {
                window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });

    }


    return (
        <div>

            <Container>
                <Row>
                    <form className="contact-panel__form" onSubmit={sendEmail} id="contactForm" >
                        <div className="row">
                            <div className="col-sm-12">
                                <h4 className="contact-panel__title" style={{ fontWeight: "bolder", color: "#2685c5" }}>Submit Your CV</h4>
                                {/* <p className="contact-panel__desc mb-30">
                                    Please feel welcome to contact our friendly reception staff with any general or medical enquiry. Our
                                    doctors will receive or return any urgent calls.
                                </p> */}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <i className="icon-user form-group-icon"></i>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        id="contact-name"
                                        name="name"
                                        required
                                        style={{ borderRadius: "1px" }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <i className="icon-email form-group-icon"></i>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        id="contact-email"
                                        name="email"
                                        required
                                        style={{ borderRadius: "1px" }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <i className="icon-phone form-group-icon"></i>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone"
                                        id="contact-Phone"
                                        name="number"
                                        required
                                        style={{ borderRadius: "1px" }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <i className="icon-news form-group-icon"></i>
                                    <input type="file" name="cv" style={{ borderRadius: "1px" }} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <i className="icon-alert form-group-icon"></i>
                                    <textarea
                                        className="form-control"
                                        placeholder="Message"
                                        id="contact-message"
                                        name="message"
                                        style={{ borderRadius: "1px" }}
                                    ></textarea>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn__secondary  btn__block btn__xhight mt-10"
                                    style={{ marginBottom: "25px", width: "5%", backgroundColor: "#2685C5" }}

                                >
                                    <span>Submit Request</span> <i className="icon-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </Row>
            </Container>


        </div>



    )
}
export default Careers