import React, { useState } from 'react';
import Careers from '../Careers/Careers';

function Navbar({ onComponentChange }) {
    const [showAppointment, setShowAppointment] = useState(false);

    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);


    const [activeNavItem, setActiveNavItem] = useState('home');

    const handleNavItemClick = (item, city) => {
        console.log(city)
        setActiveNavItem(item);
        onComponentChange(item, '', city);
    };

    const [isDepartmentsOpen, setDepartmentsOpen] = useState(false);

    const handleToggleDepartmentsOpen = () => {
        setDepartmentsOpen(true);
    };
    const handleToggleDepartmentsClose = () => {
        setDepartmentsOpen(false);
    };

    const [isLocationsOpen, setLocationsOpen] = useState(false);
    const handleToggleLocationsOpen = () => {
        setLocationsOpen(!isLocationsOpen);
    };

    return (
        <div>
            {/* <Careers show={showAppointment} handleClose={handleCloseAppointment} /> */}
            <nav className="navbar navbar-expand-lg sticky-navbar">
                <div className="container-fluid">
                    <a className="navbar-brand" href="index.html">
                        <img src="assets/images/logo/LogoTVI.jpg" className="logo-light tvilogo" alt="logo" style={{ height: '90px', marginLeft: '110px' }} />
                        <img src="assets/images/logo/LogoTVI.jpg" className="logo-dark tvilogo" style={{ maxHeight: '80px' }} />
                    </a>
                    <button className="navbar-toggler" type="button">
                        <span className="menu-lines"><span></span></span>
                    </button>
                    <div className="collapse navbar-collapse" id="mainNavigation">

                        <ul className="navbar-nav ml-auto">
                            <li className="nav__item">
                                <a href="#" className={`nav__item-link d-lg-none ${activeNavItem === 'home' ? 'active' : ''}`} onClick={() => handleNavItemClick('home')} style={{fontWeight: "lighter"}}>Home</a>
                            </li>
                            <li className="nav__item">
                                <a href="#" className={`nav__item-link d-lg-none ${activeNavItem === 'about' ? 'active' : ''}`} onClick={() => handleNavItemClick('about')}>About Us</a>
                            </li>
                            {/* <li className="nav__item">
                                <a href="#" className={`nav__item-link d-lg-none ${activeNavItem === 'news' ? 'active' : ''}`} onClick={() => handleNavItemClick('news')}>News & Media</a>
                            </li> */}
                            <li className="nav__item">
                                <a href="#" className="d-lg-none" onClick={handleToggleLocationsOpen} style={{fontSize: "15px", lineHeight: "35px", fontWeight: "700", color: "black", paddingLeft: "15px"}}>Our Locations</a>
                            </li>
                            <div style={{ display: isLocationsOpen ? 'block' : 'none' }}>
                                <li className="nav__item">
                                    <a style={{marginLeft: "20px"}} href="#" onClick={() => handleNavItemClick('cities', "Al Ain")} className="nav__item-link d-lg-none">Al Ain</a>
                                </li>
                                <li className="nav__item">
                                    <a style={{marginLeft: "20px"}} href="#" onClick={() => handleNavItemClick('cities', "Abu Dhabi")} className="nav__item-link d-lg-none">Abu Dhabi</a>
                                </li>
                                <li className="nav__item">
                                    <a style={{marginLeft: "20px"}} href="#" onClick={() => handleNavItemClick('cities', "Dubai")} className="nav__item-link d-lg-none">Dubai</a>
                                </li>
                                <li className="nav__item">
                                    <a style={{marginLeft: "20px"}} href="#" onClick={() => handleNavItemClick('cities', "RAK")} className="nav__item-link d-lg-none">Ras Al khaimah</a>
                                </li>
                                <li className="nav__item">
                                    <a style={{marginLeft: "20px"}} href="#" onClick={() => handleNavItemClick('cities', "Ajman")} className="nav__item-link d-lg-none">Ajman</a>
                                </li>
                                <li className="nav__item">
                                    <a style={{marginLeft: "20px"}} href="#" onClick={() => handleNavItemClick('cities', "Sharjah")} className="nav__item-link d-lg-none">Sharjah</a>
                                </li>
                            </div>
                            <li className="nav__item">
                                <a href="#" className={`nav__item-link d-lg-none ${activeNavItem === 'contact' ? 'active' : ''}`} onClick={() => handleNavItemClick('contact')}>Contact Us</a>
                            </li>
                            <li className="nav__item">
                                <a href="#" className={`nav__item-link d-lg-none ${activeNavItem === 'careers' ? 'active' : ''}`} onClick={() => handleNavItemClick('careers')}>Careers</a>
                            </li>



                        </ul>
                        <button className="close-mobile-menu d-block d-lg-none"><i className="fas fa-times"></i></button>
                    </div>
                    <div className="d-none d-xl-flex align-items-center position-relative ml-30" >

                        <div className="miniPopup-departments-trigger" onClick={() => handleNavItemClick('home')} style={{ marginRight: "20px" }}>
                            <a href='#' style={{ borderBottom: activeNavItem === 'home' ? "2px solid #2685c5" : "none",color: activeNavItem === 'home' ? "#2685c5" : "black" }}>Home</a>
                        </div>
                        <div className="miniPopup-departments-trigger" onClick={() => handleNavItemClick('about')} style={{ marginRight: "20px" }}>
                            <a href='#' style={{ borderBottom: activeNavItem === 'about' ? "2px solid #2685c5" : "none",color: activeNavItem === 'about' ? "#2685c5" : "black" }}>About Us</a>
                        </div>
                        {/* <div className="miniPopup-departments-trigger" onClick={() => handleNavItemClick('news')} style={{ marginRight: "20px" }}>
                            <a href='#'>News & Media</a>
                        </div> */}
                        <div className="miniPopup-departments-trigger" onMouseLeave={handleToggleDepartmentsClose} onMouseOver={handleToggleDepartmentsOpen} style={{ marginRight: "20px", color:"black" }}>
                            <a href='#'>Our Locations</a>
                        </div>
                        <div className="miniPopup-departments-trigger" onClick={() => handleNavItemClick('contact')} style={{ marginRight: "20px" }}>
                            <a href='#' style={{ borderBottom: activeNavItem === 'contact' ? "2px solid #2685c5" : "none",color: activeNavItem === 'contact' ? "#2685c5" : "black" }}>Contact us</a>
                        </div>
                        <div className="miniPopup-departments-trigger" onClick={() => handleNavItemClick('careers')} style={{ marginRight: "20px" }}>
                            <a href='#' style={{ borderBottom: activeNavItem === 'careers' ? "2px solid #2685c5" : "none",color: activeNavItem === 'careers' ? "#2685c5" : "black" }}>Careers</a>
                        </div>




                        <ul style={{ marginLeft: "150px" }} onMouseLeave={handleToggleDepartmentsClose} onMouseOver={handleToggleDepartmentsOpen} className={`miniPopup miniPopup-departments dropdown-menu ${isDepartmentsOpen == true ? 'active' : ''}`}>
                            <li className="nav__item">
                                <a href="#" onClick={() => handleNavItemClick('cities', "Al Ain")} className="nav__item-link">Al Ain</a>
                            </li>
                            <li className="nav__item">
                                <a href="#" onClick={() => handleNavItemClick('cities', "Abu Dhabi")} className="nav__item-link">Abu Dhabi</a>
                            </li>
                            <li className="nav__item">
                                <a href="#" onClick={() => handleNavItemClick('cities', "Dubai")} className="nav__item-link">Dubai</a>
                            </li>
                            <li className="nav__item">
                                <a href="#" onClick={() => handleNavItemClick('cities', "RAK")} className="nav__item-link">Ras Al khaimah</a>
                            </li>
                            <li className="nav__item">
                                <a href="#" onClick={() => handleNavItemClick('cities', "Ajman")} className="nav__item-link">Ajman</a>
                            </li>
                            <li className="nav__item">
                                <a href="#" onClick={() => handleNavItemClick('cities', "Sharjah")} className="nav__item-link">Sharjah</a>
                            </li>
                        </ul>

                        {/* <a onClick={() => handleNavItemClick('contact')} href="#" className="btn btn__primary btn__rounded ml-30">
                            <span>Contact us</span>
                        </a>
                        <a onClick={() => handleNavItemClick('careers')} href="#" className="btn btn__primary btn__rounded ml-30">
                            <span>Careers</span>
                        </a> */}
                    </div>
                </div>
            </nav>

        </div>
    )
}
export default Navbar